import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import router from "@/router";
import { message } from 'ant-design-vue';
import store from '@/store/index';

interface PendingType {
    url?: string;
    method?: Method;
    params: any;
    data: any;
    cancel: Function;
}

const pendingList: Array<PendingType> = [];
const CancelToken = axios.CancelToken;

//取消重复请求
const removePending = (config: AxiosRequestConfig): void => {
    for (const key in pendingList) {
        const item: number = +key;
        const list: PendingType = pendingList[key];

        // 当前请求在数组中存在时执行函数体
        if (
            list.url === config.url &&
            list.method === config.method &&
            JSON.stringify(list.params) === JSON.stringify(config.params) &&
            JSON.stringify(list.data) === config.data
        ) {
            // 执行取消操作
            // list.cancel("操作太频繁，请稍后再试");
            // 从数组中移除记录
            pendingList.splice(item, 1);
        }
    }
};
const token =  localStorage.token? 'Bearer '+ localStorage.token: "";


const service = axios.create({
    timeout: 120000,
    headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json; charset=utf-8",
        // "request-id": `01${RondomPass(10)}`,
        authorization: token,
        platform: "web",
        appversion: "web",
        imei: "web",
        phonemodel: "web",
        systemversion: "web",
    },
});

service.interceptors.request.use(
    (config: any): Promise<AxiosRequestConfig> | AxiosRequestConfig => {

        console.log(config);
        
        removePending(config);
        store.state.spinning = true;
        config.cancelToken = new CancelToken((c): void => {
            pendingList.push({
                url: config.url,
                method: config.method,
                params: config.params,
                data: config.data,
                cancel: c,
            });
        });

        return config;
    },
    (error: Error): Promise<never> => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (
        response: AxiosResponse
    ): AxiosResponse<object> | Promise<AxiosResponse<object>> => {
        console.log(response);
        
        // 先行删除再取消loading否则loading会一直存在
        removePending(response.config);
        store.state.spinning = true;
        if (pendingList.length == 0) {
            // Toast.clear();
            store.state.spinning = false;
        }
        let { data, code, msg } = response.data;
        
        //token 过期退出去登陆页
        if (code == 401) {
            localStorage.clear();
            message.error('登录超时，重新登录');
            setTimeout((): void => {
                router.push({ name: "login" });
            }, 1500);
        }
        return Promise.resolve(response.data);
    },
    (error: Error): Promise<never> => {
        // 判断请求异常信息中是否含有超时timeout字符串;
        if (error.message && error.message.includes("timeout") || error.message.includes("401")) {
            message.error('请求超时,请重新登陆',2);
            localStorage.clear();
            setTimeout((): void => {
                router.push({ name: "login" });
            }, 1000);
        } else {
            message.error('请求失败' + error.message,2);
        }
        store.state.spinning = false;
        return Promise.reject(error);
    }
);
export { axios, service };
